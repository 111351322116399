.document-comment {
    border-left: 5px solid transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0 0 10px;
}
.comment-header {
    height: 32px;
}

.document-comment:hover {
    border-left: 5px solid #3572b0;
    background-color: #f5f5f5;
}

.document-comment:hover > .comment-header > .comment-action {
    display: inline;
}

.document-comment > .comment-header > .comment-action {
    display: none;
}