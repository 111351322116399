.share-persona {
    padding: 5px 0 5px 5px;
    .remove-button {
        display: none;
    }
}

.share-persona:hover {
    background-color: rgb(243, 242, 241);
    .remove-button {
        display: block;
    }
}

.share-persona:active {
    background-color: rgb(237, 235, 233);
}



.ms-Facepile .ms-Image,
.ms-Facepile button .ms-Persona-initials {
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1px;
}